import http from '@/utils/http'
import {
  MarketParamsFace,
  BalanceParamsFace,
  ImageDictParamsFace,
  CudaVersionParamsFace,
  CreateOrderParamsFace
} from '@/interface/marketInterface'
interface Response {
  [propName: string]: any;
}
const selectProductInfo = (data: MarketParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/maintenance/productInfo/selectProductInfo',
    data: data
  })
}
// 获取有效的区域
const getRegionName = (): Promise<Response> => {
  return http({
    method: 'post',
    url: '/maintenance/regionManage/getRegionName'
  })
}
// 获取有效的运算卡管理id,型号
const getGpuManageModel = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/maintenance/gpuManage/getGpuManageModel'
  })
}
// 账户余额及优惠券信息
const balanceCoupon = (data: BalanceParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/user/account/balanceCoupon',
    data: data
  })
}
const queryImageDict = (data: ImageDictParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/image/image/queryImageDict',
    data: data
  })
}
const checkToolkitVersion = (data: CudaVersionParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/maintenance/calculateDevice/checkToolkitVersion',
    data: data
  })
}
const createTask = (data: CreateOrderParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/airestserver/task/createTask',
    data: data
  })
}
export {
  selectProductInfo,
  getRegionName,
  getGpuManageModel,
  balanceCoupon,
  queryImageDict,
  checkToolkitVersion,
  createTask
}
